import React from "react";
import {AQFade} from '../components/Loading_Screens/LoadingSpinner';
import ResidentList from '../components/Residents/ResidentList';
import IMG_LOGO from '../images/whiteLogo.png';
import {Switch,Route, Redirect, Router, useLocation} from "react-router-dom";
import Careplan from '../components/Residents/Careplan/Careplan';
import {Resident} from '../components/Residents/Resident/Resident';

const Main=()=>{

  const location = useLocation();
//<Redirect to='/login' />
  console.log(location.pathname);
  return(
    <React.Fragment>
          <header>
            <div></div>
            <div><img src={IMG_LOGO} /></div>
            <div></div>
          </header>  
          <main>


          <Switch>


            <Route exact path='/'>
              <ResidentList />
            </Route>

            <Route exact path='/residents'>
              <ResidentList />
            </Route>
    
            <Route path='/residents/res'>
              
              {//<Careplan resident={location.search.replace("?","")}></Careplan>
              }
              <Resident resident={location.search.replace("?","")}></Resident>
              

            </Route>
              
            <Route path='/residents/careplan'>
              
              <Careplan resident={location.search.replace("?","")}></Careplan>

            </Route>
            
              
            


                    

          </Switch>      

          </main>
          
          <footer>
            <div>Resident</div>
          </footer>

      
    </React.Fragment>
  );
}

export default Main;
import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import { FlowerSpinner } from "../../Loading_Screens/LoadingSpinner";
import EntryComment from "./EntryComment";
import EntryInputType from "./EntryInputType";

const Entries=({subSectionID})=>{
    
    const [errorMessage, setErrorMessage] = useState("");
    const [entries, setEntries] = useState([]);
    
    useEffect(()=>{

        let sData = {};

        sData = {
            "user": localStorage.getItem("userEmail"),
            "userKey": localStorage.getItem("userEmail"),
            "repType": "careplan_entries",
            "dataID": subSectionID
        };
        
        axios.post("/careplan/data", sData)
        .then(res => {
            if (("data" in res)) {
                if (res.data.length > 0) {
                    setEntries(res.data);
                }
            }             
        })
        .catch(()=>{

        });

    },[subSectionID]);
  
if (subSectionID===0) return <FlowerSpinner />;

return (
        <div className="flex-column margin-inline-auto min-width-90">
        {entries.map(e=><Entry entryData={e} />)}
        </div>
    );
}

export const Entry=({entryData})=>{

    const [showComments, setShowComments] = useState(false);
    const className = "careplan_entry";// + ss.cpss_style;
    return (

        <div 
            className={className}>
            <div className="careplan_entry_row" >
                <GetEntryText entryData={entryData} />
                <div className="careplan_entry_row">
                    <EntryInputType entry={entryData} />
                {
                (entryData.cpe_type!="liquids")
                &&
                <div
                    onClick={()=>setShowComments(!showComments)}
                    style={{marginLeft:"15px"}}>+</div>
                }
                </div>
            </div>
            
            {showComments && <EntryComment entryID={entryData.cpe_id} comment={entryData.cpe_comments} />}
        </div>

    );

}

const GetEntryText=({entryData})=>{

    if ((entryData.cpe_type=="liquids")||(entryData.cpe_type=="meal")) return(<TextInput id={entryData.cpe_name} value={entryData.cpe_name} />);
    return(<div>{entryData.cpe_name}</div>);
}


const TextInput=({id, value})=>{
    return (<input className="careplan_text_input" value={value} />)
}


export default Entries;
import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import { FlowerSpinner } from "../../Loading_Screens/LoadingSpinner";
import IMG_ARROW from '../../../images/menu/menu_down_arrow.png';
import GetIcon from "./GetIcon";
import Entries from "./Entries";
import Liquids from "./Liquids";

const SubSection=({subSectionID})=>{
    
    const [errorMessage, setErrorMessage] = useState("");
    const [subSections, setSubSections] = useState([]);
    
    useEffect(()=>{

        let sData = {};

        sData = {
            "user": localStorage.getItem("userEmail"),
            "userKey": localStorage.getItem("userEmail"),
            "repType": "careplan_sub_section",
            "dataID": subSectionID
        };
        
        axios.post("/careplan/data", sData)
        .then(res => {
            if (("data" in res)) {
                if (res.data.length > 0) {
                    setSubSections(res.data);
                }
            } 
            
        })
        .catch(()=>{

        });

    },[subSectionID]);
  
if (subSectionID===0) return <FlowerSpinner />;


return (
        <div>
        {subSections.map(ss=>{

            const className = "careplan_sub_section careplan-" + ss.cpss_style;
            
            if (ss.cpss_type == "liquids") return(<Liquids entry={ss} />)
            
            return (
                <div className={className}>
                    <div className="careplan_subsection">{ss.cpss_title}</div>
                    <Entries subSectionID={ss.cpss_id} />
                </div>
            );
        })
        }
        </div>
    );
}


export default SubSection;
import React from "react";
import IMG_PERSONAL_CARE from '../../../images/icons/personal_care.png';
import IMG_NUTRITION_HYDRATION from '../../../images/icons/nutrition_hydration.png';
import IMG_CONTINENCE from '../../../images/icons/continence.png';
import IMG_SOCIAL from '../../../images/icons/social.png';

const GetIcon=({sectionName})=>{
    return <img src={getImage(sectionName)} />;
}

function getImage(sName){
    if (sName=="ICONS::nutrition_hydration.png") return IMG_NUTRITION_HYDRATION;
    if (sName=="ICONS::personalcare.png") return IMG_PERSONAL_CARE;
    if (sName=="ICONS::continence.png") return IMG_CONTINENCE;
    if (sName=="ICONS::wellbeing.png") return IMG_SOCIAL;
    if (sName=="ICONS::personalcare.png") return IMG_PERSONAL_CARE;
    return null;
}

export default GetIcon;
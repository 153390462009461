import React, {useContext} from 'react';
import './arquella.css';
import './css/mainmenu.css';
import './css/dashboard.css';
import './assets/main.css';
import {firebaseAuth} from './firebase/provider/AuthProvider';
import Signup from './components/SignUp';
import Signin from './components/SignIn';
import {Switch,Route, Redirect} from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import AuthContext from './AuthContextProvider';
import Main from './pages/Main';

function App() {
  
  const ctx = useContext(AuthContext);
  const { token } = useContext(firebaseAuth)
  const {handleSignout,} = useContext(firebaseAuth)
  
  const handleOnIdle = event => {
    handleSignout();
    //console.log('user is idle', event)
    //console.log('last active', getLastActiveTime())
  }

  const handleOnActive = event => {
    //console.log('user is active', event)
    //console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = event => {
    //console.log('user did something', event)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    //ADC
    /*timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
    */
  })


  return (
    <React.Fragment>
      <Switch>
        <Route exact path='/' render={rProps => token === null ? <Signin /> : <Main />} />
        <Route path='/' render={rProps => token === null ? <Redirect to="/" /> : <Main />} />
        <Route exact path='/signin' component={Signin} />
        <Route exact path='/signup' component={Signup} />        
      </Switch>
    </React.Fragment>
  );
}

export default App;




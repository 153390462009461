import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import IMG_TOGGLE_NEGATIVE from '../../../images/toggle/neg.png';
import IMG_TOGGLE_POSITIVE from '../../../images/toggle/tick.png';
import IMG_TOGGLE_QUESTION from '../../../images/toggle/question.png';

const EntryInputType=({entry})=>{
    
    const [errorMessage, setErrorMessage] = useState("");
    const [entries, setEntries] = useState([]);
    
    useEffect(()=>{

        let sData = {};

        sData = {
            "user": localStorage.getItem("userEmail"),
            "userKey": localStorage.getItem("userEmail"),
            "repType": "careplan_entries",
            "dataID": 1
        };
        
        axios.post("/careplan/data", sData)
        .then(res => {

            if (("data" in res)) {
                if (res.data.length > 0) {

                    setEntries(res.data[0]);
                }
            } 
            
        })
        .catch(()=>{

        });

    },[1==2]);
  

if (entry.cpe_type=="toggle") return(<GetToggle entryID={entry.cpe_id} toggleValue={entry.cpe_value} />);

if (entry.cpe_type=="liquids") {
    return (
        <div>
            <input value={entry.cpe_value} className="careplan_liquids_entry" />
        </div>
    );
}

if (entry.cpe_type=="meal") {
    return (
        <div>
            <select className="careplan_select">
                <option 
                    value="1/4"
                    selected={(entry.cpe_value=="1/4") ? true : false}
                    >1/4</option>
                <option 
                    value="1/2"
                    selected={(entry.cpe_value=="1/2") ? true : false}
                    >1/2</option>
                <option 
                    value="3/4"
                    selected={(entry.cpe_value=="3/4") ? true : false}
                    >3/4</option>
                <option 
                    value="1"
                    selected={(entry.cpe_value=="1") ? true : false}
                    >1</option>
            </select>
        </div>
    );
}


return (
        <div className="flex-column margin-inline-auto min-width-90">
            {entry.cpe_type}: {entry.cpe_value}
        </div>
    );
}


const GetToggle=({entryID, toggleValue})=>{
    const [selectedValue, setSelectedValue] = useState(toggleValue);
    
    function updateSelectedValue(val){
        setSelectedValue(val);
    }

    return (
        <div className="toggle_container">
            <div 
                className={"toggle_border_left " + ((selectedValue=="tick") ? "toggle_positive" : "toggle_grey")}
                onClick={()=>updateSelectedValue("tick")}
                ><img src={IMG_TOGGLE_POSITIVE} /></div>
            <div 
                className={(selectedValue=="question") ? "toggle_question" : "toggle_grey"}
                onClick={()=>updateSelectedValue("question")}
                ><img src={IMG_TOGGLE_QUESTION} /></div>
            <div 
                className={"toggle_border_right " + ((selectedValue=="negative") ? "toggle_nagative" : "toggle_grey")}
                onClick={()=>updateSelectedValue("negative")}
                ><img src={IMG_TOGGLE_NEGATIVE} /></div>
        </div>
    );
}


export default EntryInputType;
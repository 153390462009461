import React, { Component, useEffect, useState } from "react";
import Avatar from '../../Avatar/Avatar';
import axios from 'axios';

export const Resident=({resident})=>{

    useEffect(()=>{
        return;
        console.log("post for data");
        let sData = {};

        sData = {
            "user": localStorage.getItem("userEmail"),
            "userKey": localStorage.getItem("userEmail"),
            "repType": "careplan_details",
            "dataID": resident
            //.res_id
        };
        
        axios.post("/careplan/data", sData)
        .then(res => {
            console.log({res});
            if (("data" in res)) {
                console.log("we have data");
                console.log(res.data[0]);
                if (res.data.length > 0) {
                    //setCareplanID(res.data[0].cp_id);
                }
            } 
            
        })
        .catch(()=>{

        });

    },[]);

    return(

      <div className="flex-column justify-between width-full" >
        
        <div className="flex-row justify-between width-full" >
            <Avatar />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className="flex-column width-full">
                {//<div>{(details.res_preferred_name!=="") ? details.res_preferred_name : details.res_first_name + " " + details.res_surname}</div>
                }
                <div>Andrew Chapman </div>
                <div>Room </div>
            </div>
        </div>
        
        <div className="flex-column justify-between width-full">
            <table>
                <tr>
                    <th>Likes</th>
                    <th>Dislikes</th>
                </tr>
                <tr>
                    <td>
                        <br />
                    </td>
                    <td>
                        People walking into his room without knocking<br />
                    </td>
                </tr>
            </table>
            
        </div>

        <div className="flex-column justify-between width-full">
            <div>Careplan todo</div>
            <ul>
                <li>Check on dressing.</li>
                <li>Tea before bed.</li>
                <li>Put Coronation Street on.</li>
            </ul>
        </div>

        <div className="resident" onClick={()=>{
            window.location.href = "/residents/careplan?" + resident;
        }}>
            View Careplan
        </div>

      </div>

    );
}


export const ResidentTab=({details, setSelectedResident})=>{

    return(

      <div className="resident" onClick={()=>{
        window.location.href = "/residents/res?" + details.res_id;
      }}>
        <Avatar />
        <div className="flex-row justify-between width-full">
            <div>{(details.res_preferred_name!=="") ? details.res_preferred_name : details.res_first_name + " " + details.res_surname}</div>
            <div>Room </div>
        </div>
      </div>

    );
}


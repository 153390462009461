import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import { FlowerSpinner } from "../../Loading_Screens/LoadingSpinner";

const EntryComment=({entryID, comment})=>{
    
    const [errorMessage, setErrorMessage] = useState("");
    const [entries, setEntries] = useState([]);
    
    useEffect(()=>{

        let sData = {};

        sData = {
            "user": localStorage.getItem("userEmail"),
            "userKey": localStorage.getItem("userEmail"),
            "repType": "careplan_entries",
            "dataID": 1
        };
        
        axios.post("/careplan/data", sData)
        .then(res => {
            console.log({res});
            
            if (("data" in res)) {
                if (res.data.length > 0) {
                    //console.log("sub sections", res.data)
                    setEntries(res.data[0]);
                }
            } 
            
        })
        .catch(()=>{

        });

    },[1==2]);
  

return (
        <div>
            <textarea className="careplan_comments" 
                value={comment}
            />
        </div>
    );
}


export default EntryComment;
import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import { FlowerSpinner } from "../../Loading_Screens/LoadingSpinner";
import IMG_ARROW from '../../../images/menu/menu_down_arrow.png';
import GetIcon from "./GetIcon";
import SubSection from "./SubSection";

const Careplan=({resident})=>{
    console.log("calling careplan");
    console.log({resident});
    const [errorMessage, setErrorMessage] = useState("");
    const [careplanSections, setCareplanSections] = useState([]);
    const [careplanID, setCareplanID] = useState(0);
    const [showSubSections, setShowSubSections] = useState(false);
    
    useEffect(()=>{
        console.log("post for data");
        let sData = {};

        sData = {
            "user": localStorage.getItem("userEmail"),
            "userKey": localStorage.getItem("userEmail"),
            "repType": "careplan_details",
            "dataID": resident
            //.res_id
        };
        
        axios.post("/careplan/data", sData)
        .then(res => {
            console.log({res});
            if (("data" in res)) {
                console.log("we have data");
                console.log(res.data[0]);
                if (res.data.length > 0) {
                    setCareplanID(res.data[0].cp_id);
                }
            } 
            
        })
        .catch(()=>{

        });

    },[]);

    useEffect(()=>{

        console.log("post for data 2");

        if (careplanID===0) return;
        let sData = {};

        sData = {
            "user": localStorage.getItem("userEmail"),
            "userKey": localStorage.getItem("userEmail"),
            "repType": "careplan_sections",
            "dataID": careplanID
        };
        
        axios.post("/careplan/data", sData)
        .then(res => {

            if (("data" in res)) {
                console.log({res});
                if (res.data.length > 0) {
                    console.log("sections", res.data)
                    setCareplanSections(res.data);
                }
            } 
            
        })
        .catch(()=>{        });

    },[careplanID]);

  
if (careplanID===0) return <FlowerSpinner />;

return (
        <div className="flex-column margin-inline-auto min-width-90">
        {
        careplanSections.map((section, i)=>
            <div className="section" key={"cpsections" + i}>
                <div className="sectionHeader">
                    <div className="sectionHeaderIcon"><GetIcon sectionName={section.cps_image} /></div>
                    <div className="sectionHeaderContent">{section.cps_name}</div>
                    <div className="sectionHeaderExpand" onClick={()=>setShowSubSections(!showSubSections)}><img src={IMG_ARROW} /></div>
                </div>
                <div className="sectionBody">
                    {(showSubSections) && <SubSection subSectionID={section.cps_id} />}
                </div>
            </div>
            )
        }
        </div>
    );
}


export default Careplan;
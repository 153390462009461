import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import { FlowerSpinner } from "../Loading_Screens/LoadingSpinner";
import Careplan from "./Careplan/Careplan";
import {ResidentTab} from "./Resident/Resident";

const ResidentList=()=>{

    const [errorMessage, setErrorMessage] = useState("");
    const [residents, setResidents] = useState(null);
    const [selectedResident, setSelectedResident] = useState(null);

    useEffect(()=>{

        let sData = {};

        sData = {
            "user": localStorage.getItem("userEmail"),
            "userKey": localStorage.getItem("userEmail"),
            "repType": "residents_list",
            "dataID": "10"
        };
        
        
        axios.post("/careplan/data", sData)
        .then(res => {

            if (("data" in res)) {
                console.log({res});
                if (res.data.length > 0) {
                    setResidents(res.data);
                }
            } 
            
        })
        .catch(()=>{

        });

    },[]);


    

    if (residents===null) return <FlowerSpinner />;
    if (selectedResident!==null) return <Careplan resident={selectedResident}/>;

    return (
        <div className="flex-column margin-inline-auto min-width-90">
        {
        residents.map((res,i)=><ResidentTab key={"residentsList" + i} details={res} setSelectedResident={setSelectedResident}></ResidentTab>)
        }
        </div>  
    );
}


export default ResidentList;
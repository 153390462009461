import React from "react";
import IMG_DEFAULT_USER from  '../../images/user.png';

const Avatar=()=>{

    return(
        <img className="avatar" src={IMG_DEFAULT_USER} />
    );
}

export default Avatar;
import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import { FlowerSpinner } from "../../Loading_Screens/LoadingSpinner";
import {Entry} from "./Entries";

const Liquids=({entry})=>{
    
    const [errorMessage, setErrorMessage] = useState("");
    const [entries, setEntries] = useState([]);
    
    useEffect(()=>{

        let sData = {};

        sData = {
            "user": localStorage.getItem("userEmail"),
            "userKey": localStorage.getItem("userEmail"),
            "repType": "careplan_entries",
            "dataID": entry.cpss_id
        };
        
        axios.post("/careplan/data", sData)
        .then(res => {
            if (("data" in res)) {
                if (res.data.length > 0) {
                    setEntries(res.data);
                }
            } 
        })
        .catch(()=>{

        });

    },[entry.cpss_id]);
  
    const className = "careplan_sub_section careplan-";// + ss.cpss_style;
    

    let total = 0;
    
    if (entries.length>0) {
        entries.map(e=>{
            total = total + parseInt(e.cpe_value);
        });
    }  

    return (
        <div className={className}>
            <div className="careplan_subsection">{entry.cpss_title}</div>
            {
                entries.map(e=><Entry entryData={e} />)

            }
            {//<Entries subSectionID={entry.cpss_id} />
}   
            <div className="careplan_total">
                <div>Total:</div>
                <div>{total}</div>
            </div>
            
        </div>
    );
}


export default Liquids;